<template>
  <v-card
    class="elevation-0 pa-5 flex-column justify-center petition-bg rounded-lg outline-primary"
    width="100%"
    outlined
  >
    <v-row v-if="this.$route.name !== 'Checkout'">
      <v-col cols="12" sm="4">
        <div class="d-flex justify-space-between">
          <v-img
            contain
            max-width="72"
            max-height="72"
            class="ml-4"
            :src="`/insuranceIcons/${service.icon}.svg`"
          ></v-img>
          <v-switch
            v-model="petition.hasProtection"
            color="primary"
            class="mt-2 d-flex d-sm-none"
            inset
          ></v-switch>
        </div>
        <v-card-title
          class="
            pb-2
            mt-0
            d-flex
            justify-start
            line-height-InsText
            text-h5
            font-weight-bold
            purple-dark--text
          "
          >{{ service.title }}</v-card-title
        >
        <div class="flex-column d-flex align-start justify-start mt-1">
          <span
            v-if="priceProtection"
            class="
              font-size-title
              text-left
              text-h3
              font-weight-bold
              primary--text
              pl-3
            "
            v-format-price
            >{{ priceProtection }}</span
          >
          <v-btn
            @click.stop="dialog = true"
            text
            class="see-all-service text-none mt-4"
          >
            <img class="mr-2" max-width="30" src="/images/share.png" />
            <span class="purple-main--text font-weight-bold">{{ $t('petition.seeAllCoverage') }}</span></v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <div class="d-flex justify-end">
          <v-switch
            v-model="petition.hasProtection"
            color="primary"
            class="mt-2 d-none d-sm-flex"
            inset
          ></v-switch>
        </div>
        <v-card-text
          class="accent--text pb-0"
          :class="{ 'd-flex justify-center': $vuetify.breakpoint.smAndDown }"
        >
          <div
            v-if="service.exclusive"
            class="
              card-badge
              pa-1
              mt-n2
              font-size-card-text
              line-height-title
              primary
              white--text
            "
          >
            {{ $t('petition.exclusiveOffer') }}
          </div>
          <v-list
            dense
            flat
            class="font-size-card-text line-height-btn"
            max-width="100%"
          >
            <v-list-item
              class="pa-0"
              v-for="(item, i) in service.items"
              :key="i"
              v-show="i < 3"
            >
              <v-list-item-icon class="mr-2">
                <v-icon color="purple-dark" class="mb-1">mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item class="law-list-item text-left mb-2 purple-dim--text" v-text="item.text">
              </v-list-item>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <v-img
            contain
            max-width="56"
            max-height="56"
            class="ml-4"
            :src="`/insuranceIcons/${service.icon}.svg`"
          ></v-img>
          <v-switch
            v-model="petition.hasProtection"
            color="primary"
            class="mt-2 d-flex"
            inset
          ></v-switch>
        </div>
        <v-card-title
          class="
            pb-5
            mt-0
            d-flex
            purple-dark--text
            justify-start
            font-weight-bold
            line-height-InsText
            font-size-card-title
          "
          >{{ service.title }}</v-card-title
        >
        <div class="flex-column d-flex align-start justify-start mt-3">
          <span
            v-if="priceProtection"
            class="
              font-size-title
              text-left
              full-width
              price-text
              primary--text
              pl-3
              text-h4
              font-weight-bold
            "
            v-format-price
            >{{ priceProtection }}</span
          >
          <v-btn
            @click.stop="dialog = true"
            text
            class="see-all-service text-none mt-4"
          >
            <img class="mr-2" max-width="30" src="/images/share.png" />
            {{ $t('petition.seeAllCoverage') }}</v-btn
          >
        </div>
        <v-col cols="12">
          <v-card-text
            class="accent--text pb-0 px-0"
            :class="{ 'd-flex justify-center': $vuetify.breakpoint.smAndDown }"
          >
            <div
              v-if="service.exclusive"
              class="
                card-badge
                pa-1
                font-size-card-text
                line-height-title
                primary
                white--text
              "
            >
              {{ $t('petition.exclusiveOffer') }}
            </div>
            <v-list
              dense
              flat
              class="font-size-card-text line-height-btn"
              max-width="100%"
            >
              <v-list-item
                class="pa-0"
                v-for="(item, i) in service.items"
                :key="i"
                v-show="i < 3"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="checks">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item class="law-list-item text-left" v-text="item.text">
                </v-list-item>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-col>
      </v-col>
    </v-row>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
      class="pa-0"
      width="750"
      v-model="dialog"
    >
      <v-card
        class="card-class"
        flat
        :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title
          class="
            full-width
            d-flex
            accent-text
            card-title-width
            justify-space-between
            font-size-card-title
            line-height-InsText
          "
        >
          <span class="d-flex justify-start">{{ service.title }}</span>
          <v-btn text fab small @click="dialog = false">
            <v-icon color="#D0D1D4">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-list
            dense
            flat
            class="font-size-card-text accent-text line-height-btn"
          >
            <v-list-item v-for="(item, i) in service.items" :key="i">
              <v-list-item-icon class="mr-3">
                <v-icon color="checks">mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-text="item.text">
              </v-list-item-content>
            </v-list-item> </v-list
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PetitionCard',
  props: {
    service: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters(['petition', 'priceProtection']),
  },
};
</script>

<style>
.price-text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 120%;
  color: #49d69f;
}

.law-list-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
  color: #2c2945;
}
</style>
