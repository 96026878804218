import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-width d-flex justify-center"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"pr-0",attrs:{"cols":"12"}},[_c('StepperLayout',[_c(VCol,{staticClass:"fill-height container pr-4",attrs:{"cols":"12"}},[_c(VRow,{staticClass:"fill-height",attrs:{"align-content":"space-between"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCol,{staticClass:"mx-auto text-center accent--text pb-sm-10 pt-0 font-weight-title font-size-InsTitle line-height-InsText px-sm-6",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"pt-7"},[_c('h2',{staticClass:"petition-title",domProps:{"innerHTML":_vm._s(_vm.title)}})])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pt-0 pb-5",attrs:{"justify":"space-between"}},[_c(VCol,{staticClass:"pt-0 pr-0 mx-auto",attrs:{"lg":7,"cols":"12"}},[_c(VCard,{staticClass:"pb-4 pt-0 elevation-0 bg-transparent",class:{
                        'rounded-lg': _vm.$vuetify.breakpoint.smAndDown,
                      },attrs:{"color":"transparent"}},[_c(VCardText,{staticClass:"pt-3 px-0"},[_vm._t("default")],2)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[(_vm.showProtection)?_c(VCol,{staticClass:"mx-auto pt-0 pr-0 d-flex align-center justify-center fill-height",staticStyle:{"height":"auto"},attrs:{"lg":"7","cols":"12"}},_vm._l((_vm.petitionServices),function(item,index){return _c(VCol,{key:index,staticClass:"d-flex align-center justify-center fill-height",attrs:{"cols":"12"}},[(_vm.petitionServices)?_c('PetitionCard',{staticClass:"mb-20",attrs:{"service":item}}):_vm._e()],1)}),1):_vm._e()],1)],1)],1),_c(VCol,{staticClass:"mx-auto d-flex justify-space-between pl-5 pl-md-3 pr-2 mb-5",attrs:{"cols":"11","sm":"11","md":"7"}},[_c(VBtn,{staticClass:"text-none px-md-13 pl-2 pr-5 rounded-xl back-btn",class:{ 'visibly-hidden': _vm.step === 1 },attrs:{"text":"","large":_vm.$vuetify.breakpoint.mdAndUp,"outlined":"","color":"primary"},on:{"click":_vm.previous}},[_c(VIcon,[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$t('previousStep')))],1),_c(VBtn,{staticClass:"petition-btn-border primary text-none px-md-15 px-7 rounded-xl mr-0",attrs:{"loading":_vm.loading,"text.":"","large":_vm.$vuetify.breakpoint.mdAndUp,"disabled":!this.petition.code},on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.$t('nextStep'))),_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }