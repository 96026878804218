export default [
  { value: 1, text: 'floorSelect.floors1' },
  { value: 2, text: 'floorSelect.floors2' },
  { value: 3, text: 'floorSelect.floors3' },
  { value: 4, text: 'floorSelect.floors4' },
  { value: 5, text: 'floorSelect.floors5' },
  { value: 6, text: 'floorSelect.floors6' },
  { value: 7, text: 'floorSelect.floors7' },
  { value: 8, text: 'floorSelect.floors8' },
  { value: 9, text: 'floorSelect.floors9' },
  { value: 10, text: 'floorSelect.floors10' },
  { value: 11, text: 'floorSelect.floors11' },
  { value: 12, text: 'floorSelect.floors12' },
  { value: 13, text: 'floorSelect.floors13' },
  { value: 14, text: 'floorSelect.floors14' },
  { value: 15, text: 'floorSelect.floors15' },
  { value: 16, text: 'floorSelect.floors16' },
  { value: 17, text: 'floorSelect.floors17' },
  { value: 18, text: 'floorSelect.floors18' },
  { value: 19, text: 'floorSelect.floors19' },
  { value: 20, text: 'floorSelect.floors20' },
  { value: 41, text: 'floorSelect.floors21' },
];
