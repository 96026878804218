<template>
  <div class="full-width d-flex justify-center">
    <v-row justify="center">
      <v-col class="pr-0" cols="12">
        <StepperLayout>
          <v-col class="fill-height container pr-4" cols="12">
            <v-row class="fill-height" align-content="space-between">
              <v-col cols="12">
                <v-col
                  cols="12"
                  md="7"
                  class="
                    mx-auto
                    text-center
                    accent--text
                    pb-sm-10
                    pt-0
                    font-weight-title font-size-InsTitle
                    line-height-InsText
                    px-sm-6
                  "
                  ><div class="pt-7">
                    <h2 v-html="title" class="petition-title"></h2>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-row
                    class="pt-0 pb-5"
                    justify="space-between"
                  >
                    <v-col class="pt-0 pr-0 mx-auto" :lg="7" cols="12">
                      <v-card
                        class="pb-4 pt-0 elevation-0 bg-transparent"
                        color="transparent"
                        :class="{
                          'rounded-lg': $vuetify.breakpoint.smAndDown,
                        }"
                      >
                        <v-card-text class="pt-3 px-0">
                          <slot />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col
                      v-if="showProtection"
                      lg="7"
                      cols="12"
                      style="height: auto"
                      class="
                        mx-auto
                        pt-0
                        pr-0
                        d-flex
                        align-center
                        justify-center
                        fill-height
                      "
                    >
                      <v-col
                        class="
                          d-flex
                          align-center
                          justify-center
                          fill-height
                        "
                        v-for="(item, index) in petitionServices"
                        :key="index"
                        cols="12"
                      >
                        <PetitionCard
                          class="mb-20"
                          v-if="petitionServices"
                          :service="item"
                        ></PetitionCard>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
              <v-col
                cols="11"
                sm="11"
                md="7"
                class="mx-auto d-flex justify-space-between pl-5 pl-md-3 pr-2 mb-5"
              >
                <v-btn
                  text
                  :large="$vuetify.breakpoint.mdAndUp"
                  @click="previous"
                  outlined
                  color="primary"
                  :class="{ 'visibly-hidden': step === 1 }"
                  class="
                    text-none
                    px-md-13
                    pl-2
                    pr-5
                    rounded-xl
                    back-btn
                  "
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t('previousStep') }}</v-btn
                >

                <v-btn
                  :loading="loading"
                  text.
                  :large="$vuetify.breakpoint.mdAndUp"
                  :disabled="!this.petition.code"
                  @click="next"
                  class="
                    petition-btn-border
                    primary
                    text-none
                    px-md-15 px-7
                    rounded-xl
                    mr-0
                  "
                  >{{ $t('nextStep')
                  }}<v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </StepperLayout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StepperLayout from '@/components/layouts/StepperLayout';
import PetitionCard from '@/components/cards/PetitionCard.vue';

export default {
  name: 'PetitionLayout',
  data: () => ({
    petitionServices: [],
    steps: [
      {
        text: 'Γράψε τα στοιχεία του ακινήτου',
        step: 1,
      },
      {
        text: 'Γράψε τα στοιχεία του ιδιοκτήτη',
        step: 2,
      },
      {
        text: 'Δες τη προσφορά σου',
        step: 3,
      },
      {
        text: 'Στείλε την αίτηση ενδιαφέροντος',
        step: 4,
      },
    ],
  }),
  components: { PetitionCard, StepperLayout },
  props: {
    title: {
      type: String,
      default: '',
    },
    next: {
      type: Function,
    },
    previous: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters(['loading', 'petition', 'step']),
    showProtection() {
      const allowedRoutes = ['Home', 'Petition', 'Checkout'];

      return (
        this.petition.code == 4682 && ~allowedRoutes.indexOf(this.$route.name)
      );
    },
  },
  mounted() {
    this.$axios
      .get('petition')
      .then(({ data }) => (this.petitionServices = data));
  },
};
</script>
<style lang="scss">
.stepper-container {
  background: #fff;
  width: 100%;

  .v-divider {
    display: none;
  }
}

.v-stepper--alt-labels .v-stepper__step {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.v-stepper__label {
    display: block;
    width: 100%;
}

.v-stepper__step--active {

  .v-stepper__label {
    span {
      color: #613CF8;
    }
  }
}

.v-stepper__step__step {
  display: none;
}

.stepper-container .v-divider {
  align-self: flex-start;
}
.theme--light.v-btn.v-btn--outlined.v-btn--text.back-btn {
  border-color: #613CF8 !important;
}
</style>
