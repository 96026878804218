export default context => {
  return {
    rulesAll: [
      value => !!value || context.$t('rules.required'),
      value =>
        /^[A-Z α-ω Α-Ω Ά-Ώ ά-ώ a-z 0-9 -]+$/.test(value) ||
        context.$t('rules.specialCharacters'),
    ],
    rulesAdress: [
      value => !!value || context.$t('rules.required'),
      value =>
        /^[A-Z α-ω Α-Ω Ά-Ώ ά-ώ a-z -]+$/.test(value) ||
        context.$t('rules.specialCharactersNumbers'),
    ],
  };
};
