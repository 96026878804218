<template>
  <PetitionLayout
    :previous="previousPage"
    :next="nextPage"
    :title="$t('advisorForm.tellUsAFewThingsAboutYourself')"
  >
    <v-form
      ref="form"
      v-model="valid"
      class="form-login py-10 px-6"
      lazy-validation
    >
      <v-row class="bottom-border pb-6" v-if="petition.code != 212">
        <v-col class="d-flex align-center pa-0" md="12" cols="12">
          <div
            v-if="petition.code != 4682"
            class="font-size-card-title line-height-title accent--text"
          >
            {{ $t('advisorForm.ownerNumber') }}
          </div>
          <div
            v-else
            class="font-size-card-title line-height-title accent--text"
          >
            {{ $t('advisorForm.ownerNumberLoss') }}
          </div>
        </v-col>
        <v-col
          class="d-flex justify-start justify-md-start px-0 pt-2"
          md="12"
          cols="12"
        >
          <v-btn-toggle
            mandatory
            v-model="petition.resort.ownerNumber"
            class="flex-row toggle-buttons"
            :class="{
              ' flex-row align-center': $vuetify.breakpoint.mdAndUp,
            }"
            color="primary"
          >
            <v-btn
              value="1"
              text
              @click="checkAmount"
              class="text-none property-btn-border rounded-lg mr-3"
              >1</v-btn
            >
            <v-btn
              value="2"
              text
              @click="checkAmount"
              class="property-btn-border text-none rounded-lg mr-3"
              >2
            </v-btn>
            <v-btn
              value="3"
              text
              @click="checkAmount"
              class="property-btn-border text-none rounded-lg mr-3"
              >3
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-row class="align-center bottom-border my-3">
          <v-col
            v-if="petition.resortType != 1"
            class="pl-4 flex-row align-center mt-0 mt-md-5 mb-0 mb-md-n8"
            cols="12"
          >
            <div class="font-size-card-title line-height-title accent--text">
              {{ $t('floor') }}
            </div>
          </v-col>
          <v-col
            v-if="petition.resortType != 1"
            class="flex-row align-center pt-8"
            cols="12"
          >
            <v-select
              v-model="petition.resort.nrOfFloors"
              class="card-text-field-border label-class"
              height="54"
              :rules="rules.rulesAll"
              dense
              outlined
              flat
              :item-text="'text'"
              item-value="value"
              :items="floorSelect"
            ></v-select>
          </v-col>
          <v-col class="flex-row align-center mt-5 mb-n8" md="10" cols="12">
            <div class="font-size-card-title line-height-title accent--text">
              {{ $t('region') }}
            </div>
          </v-col>

          <v-col class="d-flex align-center pt-7" md="12" cols="12">
            <v-text-field
              v-uppercase
              v-model="petition.resort.city"
              class="card-text-field-border label-class"
              height="54"
              :rules="rules.rulesAdress"
              dense
              outlined
              flat
            ></v-text-field>
          </v-col>
        </v-row>
      </v-row>
      <v-row align-content="start">
        <v-col class="pb-0 px-0" md="6" cols="12">
          <v-col
            class="px-0 flex-row align-center mt-0 mt-md-5 mb-0 mb-md-n8"
            cols="12"
          >
            <div class="font-size-card-title line-height-title accent--text">
              {{ $t('street') }}
            </div>
          </v-col>
          <v-col
            class="px-0 pr-md-4 d-flex align-center pt-0 pt-md-8"
            cols="12"
          >
            <v-text-field
              v-uppercase
              v-model="petition.resort.streetName"
              height="54"
              :rules="rules.rulesAll"
              class="card-text-field-border label-class"
              dense
              outlined
              flat
            ></v-text-field> </v-col
        ></v-col>
        <v-col class="py-0 py-md-3 px-0" md="6" cols="12">
          <v-col
            class="pl-0 flex-row align-center mt-0 mt-md-5 mb-md-n8"
            cols="12"
          >
            <div class="font-size-card-title line-height-title accent--text">
              {{ $t('streetNumber') }}
            </div>
          </v-col>
          <v-col class="px-0 d-flex align-center pt-0 pt-md-8" cols="12">
            <v-text-field
              v-uppercase
              v-model="petition.resort.streetNumber"
              height="54"
              :rules="rules.rulesAll"
              class="card-text-field-border label-class"
              dense
              outlined
              flat
            ></v-text-field> </v-col
        ></v-col>
      </v-row>
    </v-form>
    <v-snackbar
      rounded="lg"
      elevation="0"
      top
      color="#FFF0EF"
      class="error-snackbar"
      light
      v-model="snackbar"
    >
      <v-icon color="error">mdi-information-outline</v-icon
      ><span class="error--text ml-2">
        {{ $t('advisorForm.pleaseFillOutTheForm') }}</span
      >
    </v-snackbar>
  </PetitionLayout>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import floors from '@/helpers/floors';
import PetitionLayout from '@/components/layouts/PetitionLayout.vue';
import rules from '@/helpers/validation-rules-property';

export default {
  name: 'AboutProperty',
  data() {
    return {
      snackbar: false,
      valid: true,
      text: 'toggle',
      floors,
      rules: {},
    };
  },
  components: {
    PetitionLayout,
  },
  watch: {
    petition: {
      deep: true,
      handler() {
        this.rules = {};
      },
    },
  },
  computed: {
    ...mapGetters(['petition']),
    floorSelect() {
      return this.floors.map((floor) => {
        floor.text = this.$t(floor.text);
        return floor;
      });
    },
  },
  mounted() {
    this.checkAmount();
  },
  methods: {
    ...mapMutations(['moveToNextStep', 'moveToPreviousStep']),
    checkAmount() {
      if (this.petition.code == 212) {
        this.petition.resort.ownerNumber = 1;
      }
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.petition.resort.ownerNumber > 1) {
            return (this.petition.resort.owners[0].amount = 0);
          }
          if (this.petition.resort.ownerNumber == 1) {
            return (this.petition.resort.owners[0].amount = 100);
          }
        });
      }, 100);
    },
    previousPage() {
      this.$router.back();
      this.moveToPreviousStep();
    },
    nextPage() {
      this.rules = rules(this);

      this.$nextTick(() => {
        if (!this.$refs.form.validate()) {
          return (this.snackbar = true);
        }
        this.moveToNextStep();
        this.$router.push('/about-owner/1');
      });
    },
  },
};
</script>
